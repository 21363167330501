import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private storageService: StorageService) { }

  async canActivate(): Promise<boolean> {
    const token = await this.storageService.get('meufantoken');
    if (!token) {
      this.router.navigateByUrl('/intro');
      return false;
    }
    return true;
  }
}